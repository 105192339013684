import React from "react";
import ButtonMain from "../ButtonMain/ButtonMain";
import noteIcon from "../../assets/images/icons/note-color-icon.svg";
import budgetIcon from "../../assets/images/icons/budget-color-icon.svg";
import reportIcon from "../../assets/images/icons/report-color-icon.svg";
import invoiceIcon from "../../assets/images/icons/invoice-color-icon.svg";
import viewImage from "../../assets/images/icons/icono_ver_documentos.png";
import moment from "moment";
import "./reportContent.scss";

/**
 *
 * @param {Object} props
 * @param {String} props.title Titulo del contenido
 * @param {String} props.icon String que determina que icono se mostrará
 * @param {String} props.date Fecha del documento
 * @param {String} props.buttonLabel Texto que se mostrará en el botón
 * @param {String} props.buttonAction Acción que se ejecutará al pulsar el botón de descargar
 * @param {Function} props.viewAction Acción que se ejecutará al pulsar el botón de visualizar
 * @param {Object} props.document Objeto del documento
 * @param {String} props.type String que se renderizará como título.
 * @param {boolean} props.hasNoDownload Booleano que indica si se puede descargar el archivo o no. Si es true no tiene descarga. Si es false sí.
 * @param {boolean} props.hasViewIcon Boolean que indica si el card tiene un botón para visualizar el documento.
 * @param {String } props.subtitle Subtitulo del documento
 */

const ReportContent = (props) => {
	const {
		title,
		icon,
		date,
		price,
		buttonLabel,
		buttonAction,
		viewAction,
		document,
		type,
		hasNoDownload,
		subtitle,
		hasViewIcon,
		protocolo,
		indication,
	} = props;

	const renderIcon = () => {
		let iconRendered = null;
		if (icon === "note") iconRendered = noteIcon;
		else if (icon === "budget") iconRendered = budgetIcon;
		else if (icon === "invoice") iconRendered = invoiceIcon;
		else if (icon === "report") iconRendered = reportIcon;
		else iconRendered = reportIcon;
		return <img className="main-icon" src={iconRendered} alt={iconRendered} />;
	};

	const iconRendered = renderIcon();

	return (
		<div className="report-content">
			<div className="top">
				<div className="left">
					{!icon ? false : iconRendered}
					<div className="report-info">
						{protocolo && <p style={{ lineHeight: "0px" }}>Protocolo</p>}
						<h4 className="title">
							{type} {title} {indication}
						</h4>
						{subtitle && <p>{subtitle}</p>}
						{date && <p className="date">{moment(date).locale("es").format("DD/MM/YYYY")}</p>}
					</div>
				</div>
				<div className="right">{!price ? false : <span className="price">{price}€</span>}</div>
			</div>
			<div className="action-buttons">
				{/* <div className="bottom no-width">
					<button onClick={viewAction} className="view-button">
						<img src={viewImage} alt="view" />
					</button>
				</div> */}
				<div className="spacer" />
				{/* <div className="bottom"> */}
				<div className="bottom">
					{!hasNoDownload && (
						<ButtonMain
							label={buttonLabel}
							iconClass={"download-white"}
							type="button"
							customClass={protocolo ? "btn_protocol" : ""}
							action={buttonAction}
							actionParams={document}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReportContent;
