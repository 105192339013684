import React from "react";
import { Document } from "react-pdf";

/**
 *
 * @param {String} data Binario del archivo en base 64
 * @param {String} type Tipo de archivo que se va a renderizar
 * @param {boolean} isImage Indica si el archivo es una imagen o no.
 */

const Base64File = ({ data }) => <Document file={data} />;

export default Base64File;
